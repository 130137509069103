import React, { useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AlertIcon from './svg/DismissibleAlertIcon';
import { IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { Event, SocketContext } from 'SocketIO';

const useStyle = makeStyles({
  root: {
    backgroundColor: '#2A2A2A',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 28px',
    height: 36,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
    borderRadius: 4,
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      height: '100%',
      width: 4,
      backgroundColor: 'rgb(240, 185, 11)',
      borderRadius: '4px 0 0 4px',
    },
  },
  icon: {
    marginRight: 10,
    height: 20,
  },
  content: {
    margin: 0,
    minWidth: 0,
    display: 'inline-block',
    color: 'rgb(184, 184, 184)',
    fontSize: '14px',
  },
  viewMore: {
    marginLeft: 10,
    color: 'rgb(240, 185, 11)',
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
  },
  moreArrow: {
    margin: '2px 0px 0px 4px',
    minWidth: '0px',
    color: 'rgb(240, 185, 11)',
    fontSize: '12px',
    fill: 'rgb(240, 185, 11)',
    width: '1em',
    height: '1em',
  },
});

let timer = null;
const getRandomDelay = () => 5000 + Math.random() * 5000;

function MainAlert() {
  const classes = useStyle();
  const socket = useContext(SocketContext);
  const [mainAlert, setMainAlert] = useState();
  const [display, setDisplay] = useState(true);
  const isMobile = window.innerWidth <= 960;
  const urlContainsSetup = window.location.href.includes('setup');
  const [skipAlert, setSkipAlert] = useState(false);
  const SKIP_ALERT_PROBABILITY = 0.1;

  // Decide whether to skip alerts for this session.
  useEffect(() => {
    const storedSkip = sessionStorage.getItem('skipMainAlert');
    if (storedSkip === 'true') {
      setSkipAlert(true);
    } else if (Math.random() < SKIP_ALERT_PROBABILITY) {
      sessionStorage.setItem('skipMainAlert', 'true');
      setSkipAlert(true);
    }
  }, []);

  const handleClose = () => {
    if (mainAlert) {
      localStorage.setItem('mainAlert', mainAlert.message);
    }
    setDisplay(false);
  };

  // Schedule the GET_MAIN_ALERT event with a random delay.
  const scheduleAlert = useCallback(() => {
    if (skipAlert) return;
    const delay = getRandomDelay();
    timer = setTimeout(() => {
      timer = null;
      socket.emit('GET_MAIN_ALERT');
      scheduleAlert();
    }, delay);
  }, [socket, skipAlert]);

  useEffect(() => {
    if (!skipAlert) {
      scheduleAlert();
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [scheduleAlert, skipAlert]);

  const handleMainAlert = useCallback(alertData => {
    setMainAlert(alertData);
  }, []);

  useEffect(() => {
    const message = localStorage.getItem('mainAlert');
    setDisplay(mainAlert && message !== mainAlert.message);
  }, [mainAlert]);

  const handleLogin = useCallback(() => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    socket.emit('GET_MAIN_ALERT');
    scheduleAlert();
  }, [socket, scheduleAlert]);

  return (
    <>
      {!skipAlert && (
        <>
          <Event event={'login'} handler={handleLogin} />
          <Event event={'MAIN_ALERT_CHANGED'} handler={handleMainAlert} />
          {mainAlert && display && !isMobile && !urlContainsSetup && (
            <div className={classes.root}>
              <div className={classes.icon}>
                <AlertIcon />
              </div>
              <div style={{ flex: 'auto', display: 'flex' }}>
                <div className={classes.content}>{mainAlert?.message}</div>
                <a
                  className={classes.viewMore}
                  href={mainAlert?.link}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  View More{' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="none"
                    className={classes.moreArrow}
                  >
                    <path
                      d="M8.825 8L3.95 12.95 5 14l6-6-6-6-1.05 1.05L8.825 8z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </a>
              </div>
              <IconButton style={{ padding: 4 }} onClick={handleClose}>
                <Clear color="inherit" fontSize="small" />
              </IconButton>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default MainAlert;
